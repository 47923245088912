<template>
  <div class="content">
    <div class="banner">
        <div class="hint">
            <div><img src="@/assets/icps/img/dev.png" width="62px" height="80px"></div>
            <div>欢迎使用智能供电包系统</div>
        </div>
    </div>
    <div class="info">
      <div class="inner">
          <van-cell title="产品介绍" is-link @click="forward" />
          <van-cell title="使用说明" is-link @click="forward" />
          <van-cell title="版本号" value="v1.0.0" @click="hint"/>
      </div>
    </div>
    <tabBar />
  </div>
</template>
<script>
import tabBar from '@/components/icps/tabBar.vue'
export default {
  name: 'devIndex',
  components: {
    tabBar
  },
  methods:{
    hint(){
      this.$toast('当前是最新版本')
    },
    forward(){
      this.$toast('敬请期待')
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  height: 12rem;
  background-color: #019af3;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  text-align: center;
  .hint{
    color: white;font-size: 1rem;padding: 15% 0;
  }
}
.info{
  padding:0 4%;
  .inner{
  padding:0 2%;background-color: white;border-radius:10px;
  .van-cell{padding: 16px}
  .van-cell__title{font-weight: bold;color: #363636;}
}
}


</style>